import classNames from "classnames";
import { Link } from "gatsby";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";
import service1 from "../images/home-page/service-1.svg";
import service2 from "../images/home-page/service-2.svg";
import service3 from "../images/home-page/service-3.svg";

const ServiceCard = ({
  discColor = "bg-black",
  img,
  title,
  titleCssClass = "",
  descs,
  link,
  className = "",
  addon = false,
}: {
  link: string;
  discColor?: string;
  img: string;
  title: string;
  titleCssClass?: string;
  descs: string[];
  className?: string;
  addon?: boolean;
}) => (
  <Link to={link} className={cx("flex flex-col md:flex-row items-center", className)}>
    <div
      className={cx(
        "border border-[#474747] flex items-center justify-center relative rounded-full shrink-0 grow-0 w-[200px] h-[200px] mb-[40px] md:mb-0",
        !addon && "lg:w-[350px] lg:h-[350px]"
      )}
    >
      <img src={img} className={cx("w-full h-full", addon && "p-4")} alt={title} />
      {addon && (
        <div className="absolute left-0 top-[-5px] bg-[#474747] text-white w-[110px] h-[40px] flex items-center justify-center rounded-tl-[40px] rounded-br-[38px]">
          <div className="text-center text-[20px] font-bold">Add-On</div>
        </div>
      )}
    </div>
    <div>
      <h2
        className={cx(
          "font-bold mb-4 pl-4",
          addon ? "text-[18px]" : "text-[28px] lg:text-[36px]",
          titleCssClass
        )}
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>
      <ul className="list-inside pl-4">
        {descs.map((desc, i) => (
          <li key={i} className="inline-flex items-start">
            <span
              className={classNames(
                "inline-block w-[10px] h-[10px] mt-[8px] shrink-0 grow-0 rounded-full mr-[4px] md:mr-[8px]",
                discColor
              )}
            ></span>
            <span className="inline-block">{desc}</span>
          </li>
        ))}
      </ul>
    </div>
  </Link>
);

const HomePage = () => (
  <Fragment>
    <Helmet>
      <title>README Digital | Japanese Tier-0 Support Material Service</title>
      <meta
        name="description"
        content="README Digital creates your cloud product manual website, how-to eBook, tutorial videos all in Japanese, empowering your tier-0 local support."
      />
      <meta
        name="keywords"
        content="localization,Japanese,product manual,documentation,ebook,video"
      />
    </Helmet>
    <section
      id="home"
      className="h-[430px] bg-[url('../images/home-page/banner.png')] bg-bottom bg-cover bg-no-repeat flex"
    >
      <div className="container text-white mt-[38px] md:mt-[80px]">
        <h1 className="font-bold text-[52px] mb-2 leading-[60px] [letter-spacing: 0.05em]">
          Japanese
          <br className="md:hidden" /> Tier-0
          <br />
          Support Material Service
          <br />
          <span className="font-normal text-[28px] md:text-[42px]">for Your Cloud Product</span>
        </h1>
      </div>
    </section>
    <section id="services">
      <div className="container pt-[60px] mb-[40px] md:mb-[100px]">
        <h2 className="first-letter:text-[#F13D6F] font-bold text-[42px] text-center mb-[30px]">
          Our Services
        </h2>
        <p className="mb-[60px] text-center font-[600]">
          Many of the companies in Japan have started heading toward Digital Transformation today,
          <br />
          but there still exists a never-changing problem of the language.
          <br />
          Your Japanese leads, customers, partners are hungry for your services in the local
          language.
        </p>
        <ServiceCard
          img={service1}
          title={"Japanese Docs Site Build & Maintenance"}
          titleCssClass="bg-[linear-gradient(87.42deg,#FF7C01_0%,#F13D6F_92.32%)] bg-clip-text text-transparent"
          descs={[
            "Create, publish, maintain a Japanese docs site of your product",
            "Ideal for your tier-0 customer support",
            "Publishing under your own domain is available for higher plans",
          ]}
          discColor="bg-[#F59C00]"
          className="lg:px-[80px] mb-[40px] lg:mb-[80px]"
          link="/service/docs-website-building-and-maintenance"
        />
        <div className="grid lg:grid-cols-2 gap-x-[20px] gap-y-[40px]">
          <ServiceCard
            addon
            img={service2}
            title={"Japanese eBook Publication"}
            descs={[
              "We edit & publish a Japanese eGuideBook of your product",
              "Ideal for your brand awareness",
              "PDF delivery is also available for higher plans",
            ]}
            discColor="bg-[#AFCA0B]"
            link="/add-ons/e-book-publication"
          />
          <ServiceCard
            addon
            img={service3}
            title="Japanese Tutorial Videos"
            descs={[
              "We create simple, short but effective self-learning materials",
              "Strengthen your tier-0 customer support",
              "MP4 file delivery is also available for higher plans",
            ]}
            discColor="bg-[#00B6ED]"
            link="/add-ons/tutorial-visualization"
          />
        </div>
      </div>
    </section>
    <section id="costs">
      <div className="container pb-[64px] md:pb-[100px] text-center">
        <h2 className="text-[28px] md:text-[32px] font-bold mb-[40px]">
          <span className="text-[#F13D6F]">A</span>re you thinking of expanding your cloud product
          into Japan?
        </h2>
        <div className="max-w-[750px] bg-[#EFEFEF] rounded-[40px] mb-[40px] mx-auto py-[40px] px-[16px] md:px-[80px]">
          <p className="text-[24px] mb-[30px]">Initial Costs to Establish a Subsidiary in Tokyo</p>
          <table className="text-left w-full text-[21px] mb-[20px] font-light" cellPadding="10px">
            <thead className="border-b-[2px] border-b-black text-center">
              <tr>
                <th className="text-left font-normal">Details</th>
                <th className="text-right font-normal">Cost</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Company establishment and tax notices</td>
                <td className="text-right">$7,420</td>
              </tr>
              <tr>
                <td>Paid-in capital</td>
                <td className="text-right">$47,560.00</td>
              </tr>
              <tr>
                <td>Obtaining visas</td>
                <td className="text-right">$1,900.00</td>
              </tr>
              <tr>
                <td>Hiring local personnel</td>
                <td className="text-right">$20,830.00</td>
              </tr>
              <tr>
                <td>Setting up housing</td>
                <td className="text-right">$6,160.00</td>
              </tr>
              <tr>
                <td>Total</td>
                <td className="text-right">$131,310</td>
              </tr>
            </tbody>
          </table>
          <p className="mb-[40px] leading-[28px]">
            The most common case (Business operation costs are not included)
            <br /> 1 Managing Director from outside Japan, 1 Japanese manager to be hired locally
            <br />
            Source: JETRO "How to Setup Business in Japan, Cost Estimation"
            <br />
            <a
              href="https://www.jetro.go.jp/en/invest/setting_up/modelcase"
              target="_blank"
              rel="no-referer"
              className="underline break-all"
            >
              https://www.jetro.go.jp/en/invest/setting_up/modelcase
            </a>
          </p>
          {/* <Link
            to="/pricing"
            className="p-2 text-white inline-flex items-center justify-center w-[140px] bg-gradient-to-r from-[#F13D6F] to-[#FF7C01] rounded-[20px]"
          >
            See More
          </Link> */}
        </div>
        <p className="leading-[30px] text-[18px] list-disc list-inside">
          <span className="">Setting up your subsidiary in Tokyo is costly, of course. </span>
          <span className="block">
            But what if someone understands your product well and writes "Getting-Started" manuals
            in Japanese for you?&nbsp;
          </span>
          <span className="block">
            What if you can order for a docs site, an eBook, and tutorial videos of your product -
            all in Japanese?&nbsp;
          </span>
          <span className="block">
            Start raising your brand awareness and strengthen your customer support desk in Japan
            today with README Digital.
          </span>
        </p>
      </div>
    </section>
  </Fragment>
);

export default HomePage;
